<template>
  <div>
    <div class="r-map-balloon">
      <router-link
        :to="{
        name: 'info',
        params: { id: `${id}`},
      }"
      >
        <div class="pointer">
          <img
            v-if="img"
            class="r-map-balloon__img"
            :src="img"
            :alt="title"
          >
          <div class="r-map-balloon__info padding_b">
            <p class="roquefort">
              {{ title }}
            </p>
            <p class="mt-1 сaprino opacity-72">
              {{ address }}
            </p>
          </div>
        </div>
      </router-link>
      <div class="r-map-balloon__info padding_t">
        <div class="mt-2">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    img: {
      type: String
    },
    id: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 200px;
  @media(max-width: 491px){
    width: 100%;
  }
  &__img {
    width: 100%;
    height: 122px;
  }

  &__info {
    font-family: 'Golos UI';
    padding: 16px;
  }
}

.pointer {
  cursor: pointer;
}

.padding_b {
  padding-bottom: 0px;
}

.padding_t {
  padding-top: 0px;
}
</style>
<style>
.roquefort {
  font-size: 13px;
  line-height: 16px;
}

.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}
</style>
